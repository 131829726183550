<template>
  <v-container>
    <v-row class="mt-10 ml-sm-2 ml-md-5">
      <v-col class="text-center text-sm-left pl-md-10" cols="6" sm="2"
        ><h2>
          {{ overallGrade }}
          <v-icon color="primary">mdi-star</v-icon>
        </h2>
        <div class="text-subtitle-1">Calificacion</div>
      </v-col>

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="6" sm="2">
        <h2>{{ totalUsers }}</h2>
        <div class="text-subtitle-1">Usuarios</div>
      </v-col>

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="6" sm="2"
        ><h2>{{ clientUsers }}</h2>
        <div class="text-subtitle-1" color="black">
          <div class="text-subtitle-1">Usuarios Clientes</div>
        </div></v-col
      >

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="6" sm="2"
        ><h2>{{ providerUsers }}</h2>
        <div class="text-subtitle-1">Eventos reservados</div></v-col
      >

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="6" sm="2"
        ><h2>{{ pendingReqs }}</h2>
        <div class="text-subtitle-1">
          <router-link style="color: inherit" to="/admin/solicitudes"
            >Solicitudes Pendientes
          </router-link>
        </div></v-col
      >

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="6" sm="2"
        ><h2>{{ publishedServices }}</h2>
        <div class="text-subtitle-1">Servicios publicados</div></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    overallGrade: 4.5,
    totalUsers: 100,
    clientUsers: 30,
    providerUsers: 70,
    pendingReqs: 15,
    publishedServices: 90,
  }),
};
</script>
