<template>
  <v-container fluid ma-0 pa-0>
    <h2 class="mt-5 text-center text-sm-left">Ingresos</h2>
    <div class="text-sm-left">
      Ingresos registrados en el año {{ dataArray[yearIndex].year }}
    </div>
    <v-flex d-flex>
      <v-layout wrap>
        <div class="my-3">
          Ingreso Previsto
          <h3>{{ numberToCurrency(totalPayment) }}</h3>
        </div>
        <div class="ml-16 my-3">
          Pagado
          <h3>{{ numberToCurrency(totalPerceivedPayment) }}</h3>
        </div>
      </v-layout>
    </v-flex>
    <DxChart id="chart" :data-source="dataArray[yearIndex].content">
      <DxCommonSeriesSettings argument-field="state" type="stackedbar" />
      <DxSeries
        value-field="paymentValue"
        name="Pagado"
        stack="payment"
        color="rgba(33, 20, 95, 0.7)
"
      />
      <DxSeries
        value-field="pendingValue"
        name="Ingreso previsto"
        stack="payment"
        color="rgba(98, 37, 130, 0.7)"
      />
      <DxExport :enabled="true" />
      <DxTooltip :enabled="true" />
      <DxLegend
        position="hide"
        horizontal-alignment="center"
        vertical-alignment="bottom"
      />
    </DxChart>
    <v-tabs v-model="year" centered>
      <v-tab
        v-for="(item, index) in dataArray"
        :key="item.year"
        @click="
          yearIndex = index;
          yearNumber = item.year;
        "
      >
        {{ item.year }}
      </v-tab>
    </v-tabs>
  </v-container>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxLegend,
  DxCommonSeriesSettings,
  DxExport,
  DxTooltip,
} from "devextreme-vue/chart";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  components: {
    DxChart,
    DxSeries,
    DxLegend,
    DxCommonSeriesSettings,
    DxExport,
    DxTooltip,
  },
  data: () => ({
    year: null,
    yearIndex: 0,
    yearNumber: 2021,
    totalPayment: 3000,
    totalPerceivedPayment: 100000,
    dataArray: [
      {
        year: 2021,
        content: [
          {
            state: "ENE",
            paymentValue: 3000,
            pendingValue: 0,
          },
          {
            state: "FEB",
            paymentValue: 2700,
            pendingValue: 0,
          },
          {
            state: "MAR",
            paymentValue: 2500,
            pendingValue: 0,
          },
          {
            state: "ABR",
            paymentValue: 2200,
            pendingValue: 0,
          },
          {
            state: "MAY",
            paymentValue: 2000,
            pendingValue: 0,
          },
          {
            state: "JUN",
            paymentValue: 1900,
            pendingValue: 0,
          },
          {
            state: "JUL",
            paymentValue: 1500,
            pendingValue: 0,
          },
          {
            state: "AGO",
            paymentValue: 900,
            pendingValue: 0,
          },
          {
            state: "SEP",
            paymentValue: 1600,
            pendingValue: 0,
          },
          {
            state: "OCT",
            paymentValue: 1900,
            pendingValue: 0,
          },
          {
            state: "NOV",
            paymentValue: 200,
            pendingValue: 2500,
          },
          {
            state: "DEC",
            paymentValue: 500,
            pendingValue: 3000,
          },
        ],
      },
      {
        year: 2022,
        content: [
          {
            state: "ENE",
            paymentValue: 300,
            pendingValue: 0,
          },
          {
            state: "FEB",
            paymentValue: 270,
            pendingValue: 0,
          },
          {
            state: "MAR",
            paymentValue: 250,
            pendingValue: 0,
          },
          {
            state: "ABR",
            paymentValue: 220,
            pendingValue: 0,
          },
          {
            state: "MAY",
            paymentValue: 20,
            pendingValue: 0,
          },
          {
            state: "JUN",
            paymentValue: 10,
            pendingValue: 0,
          },
          {
            state: "JUL",
            paymentValue: 300,
            pendingValue: 0,
          },
          {
            state: "AGO",
            paymentValue: 90,
            pendingValue: 0,
          },
          {
            state: "SEP",
            paymentValue: 100,
            pendingValue: 0,
          },
          {
            state: "OCT",
            paymentValue: 190,
            pendingValue: 0,
          },
          {
            state: "NOV",
            paymentValue: 20,
            pendingValue: 250,
          },
          {
            state: "DEC",
            paymentValue: 55,
            pendingValue: 300,
          },
        ],
      },
    ],
  }),
  methods: {
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>
<style>
#chart {
  height: 440px;
}
</style>
