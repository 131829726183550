<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar />
    </div>

    <v-container class="pb-0 body-container">
      <v-row>
        <v-col cols="12">
          <h1 class="mt-10 text-center text-sm-left">
            ¡Hola {{ providerName }}!
          </h1>
        </v-col>
        <v-col cols="12">
          <h2 class="mt-5 text-center text-sm-left">Estadísticas generales</h2>
        </v-col>
      </v-row>

      <v-container>
        <Dashboard />
      </v-container>

      <v-row>
        <v-col cols="12">
          <v-container>
            <payment-chart />
          </v-container>
        </v-col>

        <v-col cols="12">
          <h2 class="mt-5 text-center text-sm-left">Solicitudes pendientes</h2>
          <Pending-requests :request-list="filterResult" />
          <div align="center" color="black">
            <router-link style="color: inherit" to="/admin/solicitudes"
              ><b>Ver todas</b>
            </router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <Footer class="mt-12" />
  </v-container>
</template>

<script>
import Navbar from "../../components/Admin/Shared/Navbar.vue";
import Footer from "../../components/Shared/Footer.vue";
import Dashboard from "../../components/Admin/Homepage/Dashboard.vue";
import PendingRequests from "../../components/Admin/Homepage/PendingRequests.vue";
import PaymentChart from "../../components/Admin/Homepage/PaymentChart.vue";

export default {
  components: {
    Navbar,
    Dashboard,
    PendingRequests,
    Footer,
    PaymentChart,
  },
  data: () => ({
    providerName: "Serena",
    currentYear: 2022,
    requestList: [
      {
        id: "1",
        name: "Nombre1",
        pendingBusiness: "Banquetes CUU",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1639636528000, //2021-dec-15
      },
      {
        id: "2",
        name: "Nombre2",
        pendingBusiness: "Empresa",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1633066528000, //2021-oct-30
      },
      {
        id: "3",
        name: "Nombre3",
        pendingBusiness: "Empresa",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1628919328000, //2021-aug-13
      },
      {
        id: "4",
        name: "Nombre4",
        pendingBusiness: "Banquetes CUU",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        date: 1638340528000, //2021-nov-30
      },
    ],
  }),
  computed: {
    filterResult() {
      return this.requestList.slice(this.requestList.length - 3);
    },
  },
  methods: {},
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
</style>
